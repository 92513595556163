






























































import Vue from "vue";

export default Vue.extend({
  name: "Extractor",

  components: {},

  data: function () {
    return {
      dataUrl: "",
      imageUploaded: false,
    };
  },

  methods: {
    setImage(files: any) {
      const fileReader = new FileReader();

      fileReader.onload = (event: any) => (this.dataUrl = event.target.result);
      fileReader.readAsDataURL(files);
    },
  },
});
